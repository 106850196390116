@import '../styles/variables';

.form-section {
    width: 100%;

    // inputs
    .form-input {
        border-radius: 10px;
        background-color: $umatch-input;
        border: none;
        height: 39px;
        padding: 0 15px;
        margin-top: 5px;

        &:disabled{
            background-color: $umatch-black-10;
            cursor: not-allowed;
        }
    }

    .label {
        margin-bottom: 0 !important;
        font-family: $umatch-font-semibold;
    }

    .invalid {
        border: 1px solid $umatch-purple !important;
        background: #F8E9E9;
        color: $umatch-purple;
    }

    .focused {
        border: 1px solid $umatch-turquoise
    }

    small {
        color: $umatch-purple;
    }
}
