// variables

//colours
$umatch-white: #ffffff;
$umatch-black: #000000;
$umatch-yellow: #fbb13c;
$umatch-yellow-bis: hsla(37, 96%, 61%, 0.2);
$umatch-turquoise: #218380;
$umatch-turquoise-light: #e9f3f2;
$umatch-purple: #d81159;
$umatch-purple-bis: #F9DBE6;
$umatch-purple-light: #f8e9e9;
$umatch-light-blue: #73d2de;
$umatch-dark-purple: #8f2d56;
$umatch-black-100: #101010;
$umatch-black-80: #404040;
$umatch-black-60: #707070;
$umatch-black-40: #9f9f9f;
$umatch-black-20: #cfcfcf;
$umatch-black-10: #e7e7e7;
$umatch-input: #e7eff0;
$umatch-invalid: #f8e9e9;
$umatch-warning: #fff3e0;

// fonts
$umatch-font: "Umatch-regular";
$umatch-font-bold: "Umatch-bold";
$umatch-font-light: "Umatch-light";
$umatch-font-semibold: "Umatch-semibold";

// breakpoint
$desktop-size: 1030px;

// breakpoint
$desktop-size: 1030px;

::ng-deep button {
  border: unset !important;
  cursor: pointer !important;
}

::ng-deep button:hover {
  border: unset !important;
  opacity: 0.7 !important;
}

::ng-deep button:disabled {
  border: unset !important;
  opacity: 0.3 !important;
}
