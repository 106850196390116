
.multiple-select {
  .label {
    color: $umatch-black-60;
    margin-bottom: 5px !important;
    font-family: $umatch-font-semibold;
  }


  .ng-select {
    .ng-select-container {
      background: $umatch-input !important;
      border: none !important;
      border-radius: 10px !important;
      min-height: 49px !important;

      .ng-arrow {
        border-color: $umatch-black transparent transparent !important;
      }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
      background: $umatch-input !important;
    }

    .ng-value {
      background: $umatch-turquoise !important;
    }

    .ng-value-icon, .ng-value-label {
      color: $umatch-white !important;
    }

    .ng-value-icon:hover{
      background: $umatch-turquoise-light !important;
      color: $umatch-black !important;
    }

    .ng-value-container {
      max-height: 100px !important;
      overflow-x: hidden;
      overflow-y: auto;
    }

  }
}
