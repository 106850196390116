@import '../../node_modules/ngx-owl-carousel-o/lib/styles/scss/theme';
@import "../styles/variables";

$color-base:			#869791 !default;
$color-white:			#FFF !default;
$color-gray:			#D6D6D6 !default;

//nav

$nav-color: 			$color-white !default;
$nav-color-hover:		$color-white !default;
$nav-font-size: 		14px !important;
$nav-rounded: 			3px !important;
$nav-margin: 			5px !important;
$nav-padding: 			4px 7px !important;
$nav-background: 		$color-gray !default;
$nav-background-hover:	$color-base !default;
$nav-disabled-opacity: 	0.5 !important;

//dots

$dot-width:				4px !important;
$dot-height:			9px !important;
$dot-rounded:			30px !important;
$dot-margin: 			5px 3px !important;
$dot-background:		$umatch-black-40 !important;



.owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
        display: inline-block;
        zoom: 1;
        display: inline;
        vertical-align: middle;

        span {
            width: $dot-width;
            height: $dot-height;
            margin: $dot-margin;
            background: $dot-background;
            display: block;
            -webkit-backface-visibility: visible;
            transition: opacity 200ms ease;
            border-radius: $dot-rounded;
        }

        &.active {
            span {
                width: $dot-width;
                height: 14px !important;
            }
        }

        &:nth-child(1).active, &:nth-child(1):hover {
          span {
            background: $umatch-yellow !important;
          }
        }

        &:nth-child(2).active, &:nth-child(2):hover {
          span {
            background: $umatch-turquoise !important;
          }
        }

        &:nth-child(3).active, &:nth-child(3):hover {
          span {
            background: $umatch-purple !important;
          }
        }
    }
}

.owl-stage-outer {
  box-shadow: 0 0 20px 6px #f3f3f3;
  border-radius: 10px;
}

.owl-stage-outer {
  box-shadow: 0 0 30px 6px $color-gray;
  border-radius: 10px;
}

.owl-stage-outer {
  box-shadow: 0 0 30px 6px $color-gray;
  border-radius: 10px;
}
