/* You can add global styles to this file, and also import other style files */
// @import './styles/inputs';
@import "./styles/variables";
@import "./styles//material";
@import "./styles/input";
@import "./styles/forms";
@import "./styles/flexbox";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "./styles/ng-select";
@import "./styles//owl-carousel";

// regular style toast
@import '~ngx-toastr/toastr';

* {
  outline: 0 !important;
  color: $umatch-black-100;
  font-family: $umatch-font;
  font-size: 14px;
  line-height: unset;
}

html,
body {
  height: 100vh;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: $umatch-font-light, Roboto, "Helvetica Neue", sans-serif;
  background-repeat: no-repeat !important;
  background-position: right top !important;
  background-size: contain !important;
}

@media only screen and (max-width: 1279px) {
  html,
  body {
    background-image: none;
  }
}

body {
  margin: 0;
}

*:focus {
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: unset !important;
}

h1 {
  font-size: 48px !important;
}

h2 {
  font-size: 18px !important;
}

//max-width
.max-width {
  width: 100%;
  margin: auto !important;
  background: none;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $umatch-turquoise;
}

// Hide Mat stepper header

.mat-horizontal-content-container {
  overflow: hidden;
  padding: 0 0 24px 0 !important;
}

.mat-stepper {
  background-color: unset;
}

// Mat menu
.mat-menu-panel {
  background-color: $umatch-purple !important;
  width: 100px !important;
  border-radius: 10px !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  color: $umatch-black-100 !important;
}

.mat-menu-content {
  background-color: $umatch-white !important;
}

// mat toolbar
.mat-toolbar {
  background: none;
  max-width: 1600px;
  margin: auto;
}

.selection-list {
  z-index: 1 !important;
}

// fonts
@font-face {
  font-family: $umatch-font;
  src: url(assets/font/Poppins-Regular.ttf) format("opentype");
}

@font-face {
  font-family: $umatch-font-bold;
  src: url(assets/font/Poppins-Bold.ttf) format("opentype");
}

@font-face {
  font-family: $umatch-font-light;
  src: url(assets/font/Poppins-Light.ttf) format("opentype");
}

@font-face {
  font-family: $umatch-font-semibold;
  src: url(assets/font/Poppins-SemiBold.ttf) format("opentype");
}

.main-content {
  padding: 30px 100px;
  min-height: 80vh;
  max-width: 1600px;
  margin: auto;
}

.main-content-full {
  padding: 30px 100px;
  min-height: 80vh;
}

.header-text {
  font-family: $umatch-font-semibold;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: $umatch-black-60;
}

@media only screen and (max-width: 760px) {
  .main-content {
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 1279px) {
  .sub-title {
    text-align: center !important;
  }

  .form-container {
    width: 100% !important;
  }

  .carousel-container {
    width: 100% !important;
  }

  html,
  body {
    height: 100vh;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: $umatch-font-light, Roboto, "Helvetica Neue", sans-serif;
    background-image: none !important;
  }
}

input {
  font-family: $umatch-font-semibold;
  color: black;
  padding: 5px 20px !important;
  &::placeholder {
    font-family: $umatch-font !important;
    color: $umatch-black-60 !important;
  }
  :-internal-autofill-selected {
    background-color: $umatch-input;
  }
}

.ngx-toastr {
  width: 500px !important;
  max-width: 90vw !important;
  margin-top: 10px !important;
  border-radius: 10px !important;
  box-shadow: 10px 10px 10px #e2e2e2 !important;

  .toast-close-button span, .toast-title, .toast-message {
    color: $umatch-white;
    line-height: 30px;
    vertical-align: middle;
  }
  &.toast-info {
    background-color: $umatch-turquoise !important;
  }
}
