@import '../styles/variables';

.form-container-mobile {
    margin-top: 50px;
}

.form-container {
    padding: 0 5px;
    height: 100%;
    width: 85%;

    .form-title  {
        font-family: $umatch-font-bold;
        color: $umatch-black-100;
        font-size: 3rem;

        .colored {
            color: $umatch-turquoise !important;
            margin: 0 10px;
        }
    }

    .sub-title, p, span{
        color: $umatch-black-100;
        font-family: $umatch-font-light;
        font-size: 1.3rem;
    }

    .small p, span {
        font-size: 1.2rem;
        color: $umatch-black-100;
        font-family: $umatch-font-light;
    }

    .form-section {
        width: 100%;
    }

    .mobile {
        margin-top: 15px;

        .label {
            margin-bottom: 0 !important;
            font-family: $umatch-font-bold;
        }

        .form-input {
            margin-top: 5px !important;
        }
    }

    .form-section input {
        height: 49px;
        padding: 0 15px;
        margin-top: 5px;
    }

    .button {
        width: 100%;
        height: 49px;
    }

    .back-button, .forward-button {
        width: 97px;
        height: 49px;
    }

    .nav {
        width: 97px;
        height: 49px;
    }

    .divider {
        margin: 15px;

        .line {
            height: 10px;
            border-bottom: 1px solid $umatch-black-20;
            margin: 0 10px;
        }
    }

    .black {
        cursor: pointer;
        font-family: $umatch-font-semibold;
    }


// Responsive media queries

@media only screen and (max-width: 1568px) {
  .form-title {
      font-size: 3rem !important;
  }
}

@media only screen and (max-width: 1396px) {
.form-title {
    font-size: 2.5rem !important;
}
}

@media only screen and (max-width: 1379px) {
.form-title {
    font-size: 3rem !important;
}
}

@media only screen and (max-width: 500px) {
    .form-title {
        font-size: 1.5rem !important;
    }

    .sub-title {
        font-size: 1.1rem !important;
    }
    }
}
